import { graphql } from "gatsby"
import React from "react"
import { IHomepage, IPageSection, IArticleSection } from "../../@types/types"
import Layout from "../components/Layout"
import PageSection from "../components/PageSection"

const ArticleList = ({
  data,
}: {
  data: { contentfulHomepage: IHomepage; allContentfulArticle: any }
}): JSX.Element => {
  const articles: Array<IArticleSection> = data.allContentfulArticle.nodes

  const links = [
    { id: "1", title: "Home", href: "/" },
    { id: "3", title: "News", href: "/articles" },
  ]
  const ordered: any = articles.sort(
    (a, b) => new Date(b.published).getTime() - new Date(a.published).getTime()
  )
  return (
    <Layout headerLinks={links} headerClassName="">
      {() =>
        ordered.map((article: any, index: number) => {
          const {
            title,
            name,
            published,
            blurb: {
              childMarkdownRemark: { html },
            },
            active,
          } = article
          const iArticle: IArticleSection = {
            name,
            title,
            published,
            blurb: html,
            active,
          }
          const page: IPageSection = {
            id: name,
            title,
            content: {
              __typename: "ContentfulArticleSection",
              ...iArticle,
            },
          }

          return (
            <PageSection
              key={name}
              index={index}
              {...page}
              onAction={() => console.log("hi")}
            />
          )
        })
      }
    </Layout>
  )
}

export default ArticleList

export const query = graphql`
  query ArticlesQuery {
    allContentfulArticle {
      nodes {
        published
        name
        title
        blurb {
          childMarkdownRemark {
            html
          }
        }
      }
    }
  }
`
